import { OptionsObject } from 'notistack'
// actions
import { axiosPost }   from 'tsRoot/actions/axios'
import { notify }                from 'tsRoot/actions/notify'
import { statusAndErrorMessage } from 'tsRoot/actions/error'
import { deleteCookies } from 'tsRoot/actions/cookie'
// others
import { CONST, env } from 'tsRoot/constants';

export async function createUser(
  params: any,
  pageName: string,
  enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject) => React.ReactText,
  setAlreadyEmail: (value: boolean) => void
) {
  try {
    if(env != 'production') {
      console.info(`url: ${`${CONST.API.MY_PAGE_HOST}/api/v1/users`}, params: ${JSON.stringify(params,null,'\t')}`)
    }

    // APIからの戻り値にtokenがありtokenを持って外部サイトからloginする
    const result = await axiosPost(`${CONST.API.MY_PAGE_HOST}/api/v1/users`, params)
    let add_params = ''
    let href_url = ''

    if(result.data.external_token) {
      add_params = `?uid=${result.data.id || ''}&ext=${result.data.external_token}`
      if(pageName === "LineRegistration") {   // Line向け特殊対応
        add_params += `&from=line`
        href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
      }
      else {
        href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
      }
    } else {
      if(pageName === "LineRegistration") {   // Line向け特殊対応
        add_params += `&from=line`
        href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
      }
      else {
        href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks`
      }
    }

    deleteCookies(pageName)
    location.href = href_url;

  } catch (error: any) {
    // Axiosのエラーの場合には通信障害
    if(error && (error.code == 'ERR_NETWORK' || error.code == 'ETIMEDOUT')) {
      return notify(enqueueSnackbar, '登録/更新機能のメンテナンス中です。時間をおいて再度実施してください', 'error')
    }

    const defaultErrorMessage = 'ユーザー登録に失敗しました'
    // @ts-ignore
    const [status, errorMessage] = statusAndErrorMessage(error.response)

    if (error.response.data.error_message[0] === 'すでに登録済みのメールアドレスです') {
      setAlreadyEmail(true)
    } else {
      notify(enqueueSnackbar, `${errorMessage || defaultErrorMessage}`, 'error')
    }
  }
}

export async function createLandingUser(
  params: any,
  pageName: string,
  startedAt: string,
  enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject) => React.ReactText
) {
  try {
    if(env != 'production') {
      console.info(`url: ${`${CONST.API.MY_PAGE_HOST}/api/v1/users`}, params: ${JSON.stringify(params,null,'\t')}`)
    }

    // APIからの戻り値にtokenがありtokenを持って外部サイトからloginする
    const result = await axiosPost(`${CONST.API.MY_PAGE_HOST}/api/v1/users`, params)

    let add_params = ''
    let href_url = ''
    if(result.data.external_token) {
      add_params = `?uid=${result.data.id || ''}&started_at=${startedAt}&ext=${result.data.external_token}`
      href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
    }
    else {
      add_params = `?started_at=${startedAt}`
      href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
    }

    deleteCookies(pageName)

    location.href = href_url;
  } catch (error: any) {
    // Axiosのエラーの場合には通信障害
    if(error && (error.code == 'ERR_NETWORK' || error.code == 'ETIMEDOUT')) {
      return notify(enqueueSnackbar, '登録/更新機能のメンテナンス中です。時間をおいて再度実施してください', 'error')
    }

    const defaultErrorMessage = 'ユーザー登録に失敗しました'
    // @ts-ignore
    const [status, errorMessage] = statusAndErrorMessage(error.response)

    notify(enqueueSnackbar, `${errorMessage || defaultErrorMessage}`, 'error')
  }
}

export async function createInterviewUser(
  params: any,
  pageName: string,
  startedAt: string,
  enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject) => React.ReactText
) {
  try {
    if(env != 'production') {
      console.info(`url: ${`${CONST.API.MY_PAGE_HOST}/api/v1/users`}, params: ${JSON.stringify(params,null,'\t')}`)
    }

    // APIからの戻り値にtokenがありtokenを持って外部サイトからloginする
    const result = await axiosPost(`${CONST.API.MY_PAGE_HOST}/api/v1/users`, params)

    let add_params = ''
    let href_url = ''
    if(result.data.external_token) {
      add_params = `?uid=${result.data.id || ''}&started_at=${startedAt}&ext=${result.data.external_token}`
      href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
    } else {
      add_params = `?started_at=${startedAt}`
      href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
    }

    deleteCookies(pageName)

    location.href = href_url;
  } catch (error: any) {
    // Axiosのエラーの場合には通信障害
    if(error && (error.code == 'ERR_NETWORK' || error.code == 'ETIMEDOUT')) {
      return notify(enqueueSnackbar, '登録/更新機能のメンテナンス中です。時間をおいて再度実施してください', 'error')
    }

    const defaultErrorMessage = 'ユーザー登録に失敗しました'
    // @ts-ignore
    const [status, errorMessage] = statusAndErrorMessage(error.response)

    notify(enqueueSnackbar, `${errorMessage || defaultErrorMessage}`, 'error')
  }
}

export async function createProjectUser(
  params: any,
  pageName: string,
  projectId: string,
  enqueueSnackbar: (message: React.ReactNode, options?: OptionsObject) => React.ReactText
) {
  try {
    if(env != 'production') {
      console.info(`url: ${`${CONST.API.MY_PAGE_HOST}/api/v1/users`}, params: ${JSON.stringify(params,null,'\t')}`)
    }

    // APIからの戻り値にtokenがありtokenを持って外部サイトからloginする
    const result = await axiosPost(`${CONST.API.MY_PAGE_HOST}/api/v1/users`, params)

    let add_params = ''
    let href_url = ''
    if(result.data.external_token) {
      add_params = `?uid=${result.data.id || ''}&project_id=${projectId}&ext=${result.data.external_token}`
      href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
    } else {
      add_params = `?project_id=${projectId}`
      href_url = `${CONST.API.SERVICE_SITE_HOST}/thanks` + add_params
    }

    deleteCookies(pageName)

    location.href = href_url;
  } catch (error: any) {
    // Axiosのエラーの場合には通信障害
    if(error && (error.code == 'ERR_NETWORK' || error.code == 'ETIMEDOUT')) {
      return notify(enqueueSnackbar, '登録/更新機能のメンテナンス中です。時間をおいて再度実施してください', 'error')
    }

    const defaultErrorMessage = 'ユーザー登録に失敗しました'
    // @ts-ignore
    const [status, errorMessage] = statusAndErrorMessage(error.response)

    notify(enqueueSnackbar, `${errorMessage || defaultErrorMessage}`, 'error')
  }
}
